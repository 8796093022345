import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import EditName from "components/EditName/EditName";
import { useDispatch, useSelector } from "react-redux";
import simulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import {
  deleteSimulation,
  getSimulation,
  runSimulation,
  updateSimulation,
} from "store/simulations/actions";
import { getSimulationItemState } from "store/simulations/selectors";
import MainContent from "components/MainContent/MainContent";
import SimulationBody, {
  ISimulationsState,
} from "components/SimulationBody/SimulationBody";
import { FormikHelpers, useFormik } from "formik";
import SimulationSchema from "pages/SimulationCreate/SimulationSchema";
import { getDefaultValues } from "pages/SimulationCreate/SimulationCreate";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import { ReactComponent as Play } from "assets/icons/play.svg";
import CommonMessages from "components/common/CommonMessages";
import { successNotifications } from "utils/successNotifications";

const SimulationEdit = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id, id } = useParams();
  const simulation = useSelector(getSimulationItemState);
  const [isDeleteWarning, setIsDeleteWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableLeaveWarning, setIsDisableLeaveWarning] = useState(false);
  useUpdateNavigation();

  const simulationInitial = useMemo(() => {
    if (simulation)
      return {
        name: simulation.name,
        resolution: simulation.resolution,
        description: simulation.description,
        frequency: simulation.frequency,
        max_scale_value: simulation.max_scale_value,
        enable_max_scale: simulation.enable_max_scale,
        crop_id: simulation.crop.id,
        steering_algorithm_id: simulation.steering_algorithm ? simulation.steering_algorithm.id : null,
        structure_id: simulation.structure.id,
        weather_dataset_id: simulation.weather_dataset.id,
        production_dataset_id: simulation.no_production_dataset ? null : simulation.production_dataset.id,
        ETP_dataset_id: simulation.ETP_dataset ? simulation.ETP_dataset.id : null,
        grassland_primary_yield_calculation: simulation.grassland_primary_yield_calculation,
        edge_effect: simulation.edge_effect
      };
    return null;
  }, [simulation]);

  const onHideWarning = () => setIsDeleteWarning(false);

  const onShowWarning = () => setIsDeleteWarning(true);

  useEffect(() => {
    dispatch(
      getSimulation({
        clientId: client_id,
        projectId: project_id,
        simulationId: id,
      })
    );
  }, [client_id, project_id, id]);

  const onBack = () =>
    navigate(
      ROUTES.SIMULATIONS.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const toResultPage = (result_id: string) => {
    setIsLoading(false);
    return navigate(
      ROUTES.SIMULATIONS_RESULT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":simulation_id", id as string)
      .replace(":id", result_id)
      );
    }

  const onDelete = () => {
    dispatch(
      deleteSimulation({
        clientId: client_id,
        projectId: project_id,
        simulationId: id,
        callback: onBack,
      })
    );
  };

  const onUpdate = (
    values: ISimulationsState,
    actions: FormikHelpers<ISimulationsState>
  ) => {
    dispatch(
      updateSimulation({
        ...values,
        clientId: client_id,
        projectId: project_id,
        simulationId: id,
        callback: () => {
          successNotifications({title: intl.formatMessage(CommonMessages.edit), message: intl.formatMessage(CommonMessages.successEdit, {objet_type: intl.formatMessage(CommonMessages.simulation)})})
          dispatch(
            getSimulation({
              clientId: client_id,
              projectId: project_id,
              simulationId: id,
            })
          );
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onUpdate,
    validationSchema: SimulationSchema(intl),
  });

  const isDataChanged = !isEqual(formik.values, simulationInitial);

  usePrompt(
    isDataChanged &&
      !formik.isSubmitting &&
      !isDeleteWarning &&
      !isDisableLeaveWarning
  );

  useEffect(() => {
    if (simulationInitial) {
      formik.setValues(simulationInitial);
    }
  }, [simulationInitial]);

  const runSimulate = () => {
    setIsLoading(true);
    dispatch(
      runSimulation({
        clientId: client_id,
        projectId: project_id,
        simulationId: id,
        callback: toResultPage,
      })
    );
  };

  const onRunSimulation = () => {
    if (isDataChanged) {
      setIsDisableLeaveWarning(true);

      dispatch(
        updateSimulation({
          ...formik.values,
          clientId: client_id,
          projectId: project_id,
          simulationId: id,
          callback: runSimulate,
        })
      );
    } else {
      runSimulate();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              onFocus={event => {event.target.select()}}
              placeholder={intl.formatMessage(
                simulationCreateMessages.simulationName
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
            <Button
                disabled={isLoading || !onRunSimulation}
                isLoading={isLoading}
                onClick={onRunSimulation}
                iconBefore={<Play />}
                className={styled.addPoint}
              >
                <FormattedMessage {...CommonMessages.run} />
              </Button>
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onShowWarning}
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>

              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !isDataChanged}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <SimulationBody formik={formik} onRunSimulation={onRunSimulation} />
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={isDeleteWarning}
          onHide={onHideWarning}
        />
      </MainContent>
    </form>
  );
};

export default SimulationEdit;
