import { useEffect, useState } from "react";

export const SolarPanel = (props: {
  panel_height: number;
  panel_x: number;
  panel_y: number;
  rotation: number;
  panel_size_height: number;
  panel_size_width: number;
  floor: { height: number; width: number };
  opacity: number;
  solar_azimuth: number;
  solar_zenith: number;
  structure_type: "tracker" | "fixed";
  tracking_max_angle: number;
  backtracking: boolean;
}) => {
  const materialProps = {
    roughness: 1,
    color: "#00006f",
  };
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (props.structure_type == "fixed") setRotation(props.rotation);
    else {
        setRotation(Math.abs(props.solar_zenith) > props.tracking_max_angle ? props.tracking_max_angle : -props.solar_zenith)
    }
  }, [rotation, props.solar_azimuth, props.solar_zenith, props.structure_type, props.tracking_max_angle]);

  return (
    <>
      <mesh
        position={[
          props.panel_y - props.floor.height / 2,
          props.panel_height / 2,
          props.panel_x - props.floor.width / 2,
        ]}
        castShadow
      >
        <cylinderGeometry
          attach="geometry"
          args={[0.3, 0.3, props.panel_height, 50]}
        />
        <meshStandardMaterial attach="material" color="#333" />
      </mesh>
      <mesh
        castShadow
        position={[
          props.panel_y - props.floor.height / 2,
          props.panel_height + 0.15,
          props.panel_x - props.floor.width / 2,
        ]}
        rotation={props.structure_type == "fixed" ? [0, 0, ((Math.PI * 2) / 360) * props.rotation] : [((Math.PI * 2) / 360) * rotation, 0, 0]}
      >
        <boxGeometry
          attach="geometry"
          args={[props.panel_size_height, 0.3, props.panel_size_width]}
        />
        <meshPhysicalMaterial
          {...materialProps}
          transparent
          opacity={props.opacity}
        />
      </mesh>
    </>
  );
};
