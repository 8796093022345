import { defineMessages } from "react-intl";

export default defineMessages({
  simulationPage: {
    id: "BatchesResultMessages.simulationPage",
    defaultMessage: "Simulation Page",
  },
  status: {
    id: "BatchesResultMessages.status",
    defaultMessage: "Status",
  },
  structureName: {
    id: "BatchesResultMessages.structureName",
    defaultMessage: "Structure Name",
  },
  batchAnalysis: {
    id: "BatchesResultMessages.batchAnalysis",
    defaultMessage: "Batch Analysis :",
  },
  irradianceFraction: {
    id: "BatchesResultMessages.irradianceFraction",
    defaultMessage: "Irradiance Fraction (%)",
  },
  meanDailyPAR: {
    id: "BatchesResultMessages.meanDailyPAR",
    defaultMessage: "Mean daily PAR (Wh/m2)",
  },
  heterogeneity: {
    id: "BatchesResultMessages.heterogeneity",
    defaultMessage: "Heterogeneity (%)",
  },
  ETPAgriPVZone: {
    id: "BatchesResultMessages.ETPAgriPVZone",
    defaultMessage: "ETP (%)",
  },
  ETPFraction: {
    id: "BatchesResultMessages.ETPFraction",
    defaultMessage: "ETP Fraction",
  },
  ETPAgriPVZoneSubTitle: {
    id: "BatchesResultMessages.ETPAgriPVZoneSubTitle",
    defaultMessage: "in agriPV zone",
  },
  waterDeficit: {
    id: "BatchesResultMessages.waterDeficit",
    defaultMessage: "Water deficit (mm)",
  },
  waterConsumption: {
    id: "BatchesResultMessages.waterConsumption",
    defaultMessage: "Water Consumption (mm)",
  },
  waterDeficitSubTitle: {
    id: "BatchesResultMessages.waterDeficitSubTitle",
    defaultMessage: "from 1/04 to 30/06 in agriPV zone",
  },
  waterDeficitGraphTitle: {
    id: "BatchesResultMessages.waterDeficitGraphTitle",
    defaultMessage: "Water deficit from 1/04 to 30/06 in agriPV zone",
  },
  farmingZoneFraction: {
    id: "BatchesResultMessages.farmingZoneFraction",
    defaultMessage: "Farming zone fraction (%)",
  },
  groundCoverageRatio: {
    id: "BatchesResultMessages.groundCoverageRatio",
    defaultMessage: "Ground Coverage Ratio (%)",
  },
  installedCapacity: {
    id: "BatchesResultMessages.installedCapacity",
    defaultMessage: "Installed Capacity (kWc/ha)",
  },
  fractionAndHeterogeneityOfIrradiance: {
    id: "BatchesResultMessages.fractionAndHeterogeneityOfIrradiance",
    defaultMessage: "Irradiance Fraction and Heterogeneity",
  },
  GCRAndInstalledCapacity: {
    id: "BatchesResultMessages.GCRAndInstalledCapacity",
    defaultMessage: "Ground Coverage Ratio and Installed Capacity",
  },
  meanDailyPARHelp: {
    id: "BatchesResultMessages.meanDailyPARHelp",
    defaultMessage: "Photosynthetically Active Radiation defined as electromagnetic radiation in the spectral range of 400 nm to 700 nm that photosynthetic organisms can use in the process of photosynthesis to fix carbon in CO2 in carbohydrates. The PAR corresponds to 48% of the luminous flux emitted by the sun.",
  },
  heterogeneityHelp: {
    id: "BatchesResultMessages.heterogeneityHelp",
    defaultMessage: "It is an indicator which makes it possible to reflect the heterogeneity of the radiation induced by the chosen solution. If heterogeneity = 0, then the radiation received is perfectly homogeneous.",
  },
  ETPAgriPVZoneHelp: {
    id: "BatchesResultMessages.ETPAgriPVZoneHelp",
    defaultMessage: "Potential evapotranspiration (ETP) or reference evapotranspiration (ETo), can be defined as the sum of the transpiration of the plant cover and the evaporation of the soil which could occur in the event of an unconstrained water supply for a given area. low, continuous and homogeneous plant cover without any limitation (nutritional, physiological or pathological).",
  },
  waterDeficitHelp: {
    id: "BatchesResultMessages.waterDeficitHelp",
    defaultMessage: "The water deficit (or balance) is calculated by the difference between precipitation and potential evapotranspiration over a given period.",
  },
  farmingZoneFractionHelp: {
    id: "BatchesResultMessages.farmingZoneFractionHelp",
    defaultMessage: "The farming zone corresponds to the cultivated area in relation to the total area covered by the agrivoltaic installation (decree 1, Art. R. 314-116). The area which is no longer usable due to the agrivoltaic installation, excluding technical premises not located on the plot, does not exceed 10% of the total area covered by the agrivoltaic installation.",
  },
  groundCoverageRatioHelp: {
    id: "BatchesResultMessages.groundCoverageRatioHelp",
    defaultMessage: "The coverage rate of the agrivoltaic installation is defined as the ratio between the maximum projected surface area of the photovoltaic modules on the agricultural plot mentioned in article R. 314-108 and the surface area of the agricultural plot defined in article R. 314-108, under normal conditions of use (Art. R. 314-108 - The agricultural plot to be considered for the application of article L. 314-36 of the relative energy code corresponds to a continuous agricultural surface presenting the same characteristics and concerned by the agrivoltaic project.) If the coverage rate is 100%, this means that the entire surface is covered with panels. For technologies of more than 10 MW, this coverage rate does not exceed 40%.",
  },
  installedCapacityHelp: {
    id: "BatchesResultMessages.installedCapacityHelp",
    defaultMessage: "The installed capacity corresponds to the photovoltaic power installed per surface unit of the agrivoltaic installation.",
  },
  irradianceFractionHelp: {
    id: "BatchesResultMessages.irradianceFractionHelp",
    defaultMessage: "The irradiance fraction corresponds to the share of the total emitted radiation received by the crop.",
  },
  waterStress: {
    id: "BatchesResultMessages.waterStress",
    defaultMessage: "Number of days in water stress",
  },
  irrigationValue: {
    id: "BatchesResultMessages.irrigationValue",
    defaultMessage: "Irrigation per period (mm)",
  },
  waterStressSubTitleInZT: {
    id: "BatchesResultMessages.waterStressSubTitleInZT",
    defaultMessage: "in witness zone",
  },
  precipitation: {
    id: "BatchesResultMessages.precipitation",
    defaultMessage: "Precipitation (mm)",
  },
  hintDatasToPng: {
    id: "WaterBalanceResultBodyMessages.hintTableToPng",
    defaultMessage: "Download datas in png",
  },
  hintDatasToCsv: {
    id: "WaterBalanceResultBodyMessages.hintTableToCsv",
    defaultMessage: "Download datas in csv",
  },
});
