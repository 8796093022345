import React, { useEffect, useMemo, useState } from "react";
import { Dropzone } from "../DragUploader/DragUploader";
import { Icon, ICON_NAMES_ENUM } from "../common/icon/Icon";
import styled from "./DatasetModel.module.scss";
import { IFormik } from "components/common/FormikType";
import Select from "components/common/select/Select";
import { FormattedMessage, useIntl } from "react-intl";
import datasetModelMessages from "./DatasetModelMessages";
import TextArea from "components/common/textArea/TextArea";
import { selectOptionsText } from "components/common/select/selectOptionsText";
import { useSelector } from "react-redux";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import DatasetEditMessages from "../../pages/DatasetsCreate/DatasetsEditMessages";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getTimezonesList } from "store/datasets/selectors";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import clsx from "clsx";

interface IError {
  name?: string;
  dataset_type?: string;
  timezone?: string;
  frequency?: string;
  comment?: string;
}

const color = ["#A66", "#6A6", "#66A", "#555", "#AA6", "#A6A", "#6AA"];

export interface IDatasetModel {
  formik: IFormik<any>;
  handelChange: (key: any, value: any) => void;
  errors: IError;
  monthly_points?: Array<any> | undefined;
  actual_dataset_type?: string | undefined;
  utc?: number | undefined;
}

const DatasetModel: React.FC<IDatasetModel> = ({
  formik,
  errors,
  handelChange,
  utc = undefined,
  monthly_points = undefined,
  actual_dataset_type = undefined,
}) => {
  const intl = useIntl();
  const { datasetTypes, frequencyOptions } = selectOptionsText();
  const [inactive_monthly_key, set_inactive_monthly_key] = useState<
    Array<string>
  >(Array());
  const [ETP_value_selected, set_ETP_value_selected] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [offset, setOffset] = useState("")

  const getLabelForETPValue = (valueName: string) => {
    switch (valueName) {
      case "air_temperature":
        return intl.formatMessage(DatasetEditMessages.air_temperature);
      case "wind_velocity":
        return intl.formatMessage(DatasetEditMessages.wind_velocity);
      case "relative_humidity":
        return intl.formatMessage(DatasetEditMessages.absolute_humidity);
      case "infrared_radiation":
        return intl.formatMessage(DatasetEditMessages.infrared_radiation);
      case "air_pressure":
        return intl.formatMessage(DatasetEditMessages.air_pressure);
      case "precipitation":
        return intl.formatMessage(DatasetEditMessages.precipitation);
      default:
        return "";
    }
  };

  const ETPValueSelectorOptions = useMemo(() => {
    if (monthly_points && monthly_points.length) {
      return Object.keys(monthly_points[0])
        .filter((item) => item != "month")
        .map((item) => ({ value: item, label: getLabelForETPValue(item) }));
    }
    return undefined;
  }, [monthly_points]);

  useEffect(() => {
    if (ETPValueSelectorOptions && ETPValueSelectorOptions.length)
      set_ETP_value_selected(ETPValueSelectorOptions[0]);
  }, [ETPValueSelectorOptions]);

  const getUniteForETPValue = (valueName: string) => {
    switch (valueName) {
      case "air_temperature":
        return "°C";
      case "wind_velocity":
        return "m/s";
      case "relative_humidity":
        return (
          <p>
            g<span className={styled.indice}>water</span>/kg
            <span className={styled.indice}>dry air</span>
          </p>
        );
      case "infrared_radiation":
        return "W/m²";
      case "air_pressure":
        return "hPa";
      case "precipitation":
        return "mm";
      default:
        return "";
    }
  };

  const onUploadFile = ([newFile]: File[]) => {
    handelChange("dataset_filename", newFile.name);
    handelChange("upload", newFile);
  };

  const onDeleteFile = () => {
    handelChange("upload", null);
    handelChange("dataset_filename", "");
  };

  const chartToCsv = (chartData: any, title: string) => () => {
    const generateCSV = (data: any) => {
      let csvContent = "";

      if (data.length === 0) {
        return csvContent;
      }
      const keys = Object.keys(data[0]);
      csvContent += keys.join(",") + "\n";
      data.forEach((item: any) => {
        const row = keys.map((key) => item[key]);
        csvContent += row.join(",") + "\n";
      });
      return csvContent;
    };

    const saveFile = (file: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    };

    const csvData = generateCSV(chartData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], `${title}.csv`, {
          type: "text/csv;charset=utf-8",
        });
        URL.revokeObjectURL(url);
        saveFile(file);
      });
  };

  const chartToPng = (chartId: string) => () => {
    const chartElement = document.getElementById(chartId);

    html2canvas(chartElement!)
      .then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.href = dataURL;
        downloadLink.download = `${chartId}.png`;

        downloadLink.click();
      })
      .catch((error) => {
        errorNotifications(`Erreur lors de la création du PNG : '${error}'`);
      });
  };

  const displayUTC = () => {
    if (utc === 0)
      return 'UTC0'
    return utc ? `UTC${utc < 0 ? '-' : '+'}${utc && utc % 3600 == 0 ? Math.abs(utc / 3600)  : `${Math.floor(Math.abs(utc / 3600))}:${(utc % 3600) / 60}`}` : ""
  }

  return (
    <>
      <div className={styled["left-column"]}>
        <Select
          label={intl.formatMessage(datasetModelMessages.datasetType)}
          options={datasetTypes}
          value={datasetTypes.find(
            ({ value }: any) => value === formik.values.dataset_type
          )}
          onChange={(e: any) =>
            handelChange("dataset_type", e.value ? e.value : "")
          }
          errorMessage={formik.touched.dataset_type ? errors.dataset_type : ""}
          isSearchable={false}
          menuHeight={400}
        />
        <Select
          label={intl.formatMessage(datasetModelMessages.samplingRate)}
          options={frequencyOptions}
          value={frequencyOptions.find(
            ({ value }: any) => value === formik.values.frequency
          )}
          onChange={(e: any) =>
            handelChange("frequency", e.value ? e.value : "")
          }
          isSearchable={false}
          errorMessage={formik.touched.frequency ? errors.frequency : ""}
          menuHeight={400}
          isDisabled={true}
        />

        <div className={styled.tzContainer} >
          <label className={styled.labelTz}> {intl.formatMessage(datasetModelMessages.timezone)} </label>
          <span className={styled.spanTz} > {displayUTC()}</span>
        </div> 


        <label htmlFor="" className={styled.label}>
          <FormattedMessage {...datasetModelMessages.comment} />
        </label>
        <TextArea
          name="comment"
          value={formik.values.comment ?? ""}
          onChange={formik.handleChange}
        />
      </div>
      <div className={styled["right-column"]}>
        <label htmlFor="" className={styled.label}>
          <FormattedMessage {...datasetModelMessages.datasetFile} />
        </label>

        {formik.values.dataset_filename ? (
          <>
            <div className={styled.file}>
              <div className={styled.file__inner}>
                <Icon
                  name={ICON_NAMES_ENUM.file}
                  className={styled.file__icon}
                />
                <p>{formik.values.dataset_filename}</p>
              </div>
              <button className={styled["delete-file"]} onClick={onDeleteFile}>
                <Icon
                  name={ICON_NAMES_ENUM.delete}
                  className={styled.zone__image}
                />
              </button>
            </div>
            <div className={styled.limiter} />
            {monthly_points &&
            monthly_points.length &&
            actual_dataset_type == "weather" ? (
              <div className={styled.chartContainer}>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("monthlyChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToCsv
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToCsv(
                        monthly_points,
                        `${formik.values.name} Weather Dataset (Monthly)`
                      )}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_file}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
                <div className={styled.labelsContainer}>
                  <div className={styled.label}>{"W/m²"}</div>
                </div>
                <div id="monthlyChart">
                <div className={styled.YAxisName}>Global light component</div>
                  
                  <ResponsiveContainer width="100%" height={250}>
                    <ComposedChart
                      data={monthly_points.map((item: any) => ({
                        month: item.month,
                        dhi: Math.round(item.dhi * 10) / 10,
                        ghi: Math.round((item.ghi - item.dhi) * 10) / 10,
                      }))}
                      width={700}
                      height={300}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey={"month"}/>
                      <YAxis />
                      <Tooltip />
                      <Legend
                        onClick={(o) => {
                          if (
                            !inactive_monthly_key.find(
                              (inactiveKey) => inactiveKey == o.dataKey
                            )
                          )
                            set_inactive_monthly_key([
                              ...inactive_monthly_key,
                              o.dataKey,
                            ]);
                          else {
                            var temp = inactive_monthly_key;
                            temp.splice(
                              inactive_monthly_key.findIndex(
                                (inactiveKey) => inactiveKey == o.dataKey
                              ),
                              1
                            );
                            set_inactive_monthly_key([...temp]);
                          }
                        }}
                      />
                      <Bar
                        type="monotone"
                        dataKey={"dhi"}
                        name={intl.formatMessage(datasetModelMessages.dhi)}
                        fill={color[2]}
                        stackId={1}
                        hide={
                          inactive_monthly_key.find(
                            (inactiveKey) => inactiveKey == "dhi"
                          )
                            ? true
                            : false
                        }
                      />
                      <Bar
                        type="monotone"
                        dataKey={"ghi"}
                        name={intl.formatMessage(datasetModelMessages.ghi)}
                        fill={color[1]}
                        stackId={1}
                        hide={
                          inactive_monthly_key.find(
                            (inactiveKey) => inactiveKey == "ghi"
                          )
                            ? true
                            : false
                        }
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
                <TooltipMUI
                  title={intl.formatMessage(
                    SimulationResultBodyMessages.hintLegend
                  )}
                >
                  <div className={styled.chartTitle}>
                    {intl
                      .formatMessage(datasetModelMessages.chartTitle)
                      .replace(":value_name", "Irradiance")}
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </div>
            ) : monthly_points &&
              monthly_points.length &&
              actual_dataset_type == "production" ? (
              <div className={styled.chartContainer}>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("monthlyChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToCsv
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToCsv(
                        monthly_points,
                        `${formik.values.name} Production Dataset (Monthly)`
                      )}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_file}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
                <div className={styled.labelsContainer}>
                  <div className={styled.label}>{"kWh/kWc"}</div>
                </div>
                <div id="monthlyChart">
                  <ResponsiveContainer width="100%" height={250}>
                    <ComposedChart
                      data={monthly_points}
                      width={700}
                      height={300}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey={"month"} />
                      <YAxis />
                      <Tooltip />
                      <Legend
                        onClick={(o) => {
                          if (
                            !inactive_monthly_key.find(
                              (inactiveKey) => inactiveKey == o.dataKey
                            )
                          )
                            set_inactive_monthly_key([
                              ...inactive_monthly_key,
                              o.dataKey,
                            ]);
                          else {
                            var temp = inactive_monthly_key;
                            temp.splice(
                              inactive_monthly_key.findIndex(
                                (inactiveKey) => inactiveKey == o.dataKey
                              ),
                              1
                            );
                            set_inactive_monthly_key([...temp]);
                          }
                        }}
                      />
                      <Bar
                        type="monotone"
                        dataKey={"prod"}
                        name="Production agriPV"
                        fill={color[1]}
                        stackId={1}
                        hide={
                          inactive_monthly_key.find(
                            (inactiveKey) => inactiveKey == "prod"
                          )
                            ? true
                            : false
                        }
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
                <TooltipMUI
                  title={intl.formatMessage(
                    SimulationResultBodyMessages.hintLegend
                  )}
                >
                  <div className={styled.chartTitle}>
                    {intl
                      .formatMessage(datasetModelMessages.chartTitle)
                      .replace(":value_name", "Production")}
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </div>
            ) : monthly_points &&
              monthly_points.length &&
              ETPValueSelectorOptions &&
              ETP_value_selected &&
              actual_dataset_type == "ETP" ? (
              <div className={styled.chartContainer}>
                <div
                  className={styled.ETPValueSelector}
                  onMouseEnter={() =>
                    (document.documentElement.style.overflow = "hidden")
                  }
                  onMouseLeave={() =>
                    (document.documentElement.style.overflow = "auto")
                  }
                >
                  {ETPValueSelectorOptions.map((item) => (
                    <div
                      key={item.value}
                      className={clsx(styled.option, {
                        [styled.active]:
                          item.label == ETP_value_selected!.label,
                      })}
                      style={
                        item.label == ETP_value_selected!.label
                          ? {
                              borderColor:
                                color[
                                  Object.keys(monthly_points[0]).findIndex(
                                    (item) => item == ETP_value_selected.value
                                  ) % color.length
                                ],
                            }
                          : {}
                      }
                      onClick={() => {
                        set_ETP_value_selected(item);
                      }}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
                <div className={styled.downloadContainer}>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToPng
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToPng("monthlyChart")}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_image}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                  <TooltipMUI
                    title={intl.formatMessage(
                      SimulationResultBodyMessages.hintChartToCsv
                    )}
                  >
                    <div
                      className={styled.chartToPngButton}
                      onClick={chartToCsv(
                        monthly_points,
                        `${formik.values.name} ETP Dataset (Monthly)`
                      )}
                    >
                      <Icon
                        name={ICON_NAMES_ENUM.download_file}
                        className={styled.file__icon}
                      />
                    </div>
                  </TooltipMUI>
                </div>
                <div className={styled.labelsContainer}>
                  <div className={styled.label}>
                    {getUniteForETPValue(
                      ETP_value_selected ? ETP_value_selected.value : ""
                    )}
                  </div>
                </div>
                <div id="monthlyChart">
                  <ResponsiveContainer width="100%" height={250}>
                    <ComposedChart
                      data={monthly_points}
                      width={700}
                      height={300}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey={"month"} />
                      <YAxis />
                      <Tooltip />
                      <Legend
                        payload={
                          ETP_value_selected && monthly_points
                            ? [
                                {
                                  id: ETP_value_selected.value,
                                  type: "circle",
                                  value: ETP_value_selected.label,
                                  color:
                                    color[
                                      Object.keys(monthly_points[0]).findIndex(
                                        (item) =>
                                          item == ETP_value_selected.value
                                      ) % color.length
                                    ],
                                  inactive:
                                    inactive_monthly_key.findIndex(
                                      (item) => item == ETP_value_selected.value
                                    ) >= 0
                                      ? true
                                      : undefined,
                                },
                              ]
                            : undefined
                        }
                        onClick={(o) => {
                          if (
                            !inactive_monthly_key.find(
                              (inactiveKey) =>
                                inactiveKey == o.dataKey || inactiveKey == o.id
                            )
                          )
                            set_inactive_monthly_key([
                              ...inactive_monthly_key,
                              o.dataKey ? o.dataKey : o.id,
                            ]);
                          else {
                            var temp = inactive_monthly_key;
                            temp.splice(
                              inactive_monthly_key.findIndex(
                                (inactiveKey) =>
                                  inactiveKey == o.dataKey ||
                                  inactiveKey == o.id
                              ),
                              1
                            );
                            set_inactive_monthly_key([...temp]);
                          }
                        }}
                      />
                      {ETP_value_selected && monthly_points ? (
                        <Bar
                          type="monotone"
                          dataKey={ETP_value_selected.value}
                          name={ETP_value_selected.label}
                          fill={
                            color[
                              Object.keys(monthly_points[0]).findIndex(
                                (item) => item == ETP_value_selected.value
                              ) % color.length
                            ]
                          }
                          stackId={1}
                          hide={
                            inactive_monthly_key.find(
                              (inactiveKey) =>
                                inactiveKey == ETP_value_selected.value
                            )
                              ? true
                              : false
                          }
                        />
                      ) : (
                        ""
                      )}
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
                <TooltipMUI
                  title={intl.formatMessage(
                    SimulationResultBodyMessages.hintLegend
                  )}
                >
                  <div className={styled.chartTitle}>
                    {intl
                      .formatMessage(datasetModelMessages.chartTitle)
                      .replace(":value_name", ETP_value_selected.label)}
                    <div className={styled["help-icon"]}>
                      <Icon
                        name={ICON_NAMES_ENUM.help_circle}
                        className={styled.file__icon}
                      />
                    </div>
                  </div>
                </TooltipMUI>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Dropzone
            accept={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ]}
            maxSize={10000000}
            multiple={false}
            onChange={onUploadFile}
            errorMessage={
              (formik.touched.upload && !!formik.errors.upload) ||
              (formik.touched.dataset_filename &&
                !!formik.errors.dataset_filename)
            }
          />
        )}
      </div>
    </>
  );
};

export { DatasetModel };
