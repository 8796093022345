import { defineMessages } from "react-intl";

export default defineMessages({
  datasetName: {
    id: "DatasetsCreateMessages.datasetName",
    defaultMessage: "Dataset Name",
  },
  datasetUnnamed: {
    id: "DatasetsCreateMessages.datasetUnnamed",
    defaultMessage: "Dataset Unnamed",
  },
  dailyChart: {
    id: "DatasetsCreateMessages.dailyChart",
    defaultMessage: "Daily Chart",
  },
  monthlyChart: {
    id: "DatasetsCreateMessages.monthlyChart",
    defaultMessage: "Monthly Chart",
  },
  selectValue: {
    id: "DatasetsCreateMessages.selectValue",
    defaultMessage: "Select Value",
  },
  air_temperature: {
    id: "DatasetsCreateMessages.air_temperature",
    defaultMessage: "Air Temperature",
  },
  wind_velocity: {
    id: "DatasetsCreateMessages.wind_velocity",
    defaultMessage: "Wind Velocity",
  },
  absolute_humidity: {
    id: "DatasetsCreateMessages.absolute_humidity",
    defaultMessage: "Absolute Humidity",
  },
  infrared_radiation: {
    id: "DatasetsCreateMessages.infrared_radiation",
    defaultMessage: "Infrared Radiation",
  },
  air_pressure: {
    id: "DatasetsCreateMessages.air_pressure",
    defaultMessage: "Air Pressure",
  },
  precipitation: {
    id: "DatasetsCreateMessages.precipitation",
    defaultMessage: "Precipitation",
  },
});
