import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getDatasets as action } from "store/datasets/actions";
// api
import { getDatasets as api } from "store/datasets/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const datasets = [];
    const datasetTypes = ["weather", "production", "ETP"];
    for (let i = 0; i < datasetTypes.length; i++) {
      const type = datasetTypes[i];
      if (payload?.type.includes(type)) {
        const datasetResponse = yield call(api, {
          clientId: payload?.clientId,
          projectId: payload.projectId,
          type,
        });
        datasets.push(...datasetResponse.data);
      }
    }
    yield put(
      action.success(datasets)
    );
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getDatasets() {
  yield takeLatest(action.TRIGGER, saga);
}
