import React from "react";
import styled from "./AddModalPeriodCropsTable.module.scss";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { FormattedMessage, useIntl } from "react-intl";
import addModalPeriodCropsTable from "./AddModalPeriodCropsTableMessages";
import Input from "components/common/input/Input";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import DatePicker from "components/common/datepicker/DatePicker";
import { IAddPointModal } from "./AddModalPeriodCropsTableTypes";
import { AddModalPeriodCropsTableHooks } from "./AddModalPeriodCropsTableHooks";
import Select from "components/common/select/Select";
import { selectOptionsText } from "components/common/select/selectOptionsText";
import { Tooltip } from "@mui/material";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";

function AddModalPeriodCropsTable({
  show,
  onHide,
  onSave,
  periods,
  isEdit,
  onEdit,
  currentPeriod,
  title,
  type
}: IAddPointModal) {
  const intl = useIntl();

  const {
    models: { formik, isDatepicker },
    commands: { onSelect },
  } = AddModalPeriodCropsTableHooks({
    onSave,
    periods,
    isEdit,
    onEdit,
    currentPeriod,
    show,
    type
  });

  const { degreeDays } = selectOptionsText();

  return (
    <ModalContainer show={show} onHide={onHide} title={title}>
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          autoFocus={!isEdit}
          onFocus={event => {event.target.select()}}
          name="name"
          label={intl.formatMessage(addModalPeriodCropsTable.stageName)}
          value={formik.values.name}
          onChange={formik.handleChange}
          errorMessage={formik.touched.name ? formik.errors.name : ""}
        />
        <Input
          name="crop_height"
          label={intl.formatMessage(addModalPeriodCropsTable.cropHeight)}
          type="number"
          onChange={formik.handleChange}
          value={formik.values.crop_height}
          className={!!formik.errors.crop_height ? styled["input-invalid"] : ""}
        />
        {type == "normalDates" ? (
          <div className={styled.dates}>
            <DatePicker
              openDown={false}
              id="dateStart"
              label={intl.formatMessage(addModalPeriodCropsTable.dateStart)}
              onChange={(date) => formik.setFieldValue("dateStart", date)}
              date={formik.values.dateStart}
              errorMessage={
                formik.touched.dateStart ? formik.errors.dateStart : ""
              }
            />

            <DatePicker
              openDown={false}
              id="dateEnding"
              label={intl.formatMessage(addModalPeriodCropsTable.dateEnd)}
              onChange={(date) => formik.setFieldValue("dateEnding", date)}
              date={formik.values.dateEnding}
              errorMessage={
                formik.touched.dateEnding ? formik.errors.dateEnding : ""
              }
            />
          </div>
        ) : (
          <div className={styled.dates}>
            <Input
              label={intl.formatMessage(addModalPeriodCropsTable.startDegreeDay)}
              name={"start_degree_day"}
              type="number"
              step={1}
              value={formik.values.start_degree_day}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.touched.start_degree_day ? formik.errors.start_degree_day : undefined}
            />
            <Input
              label={intl.formatMessage(addModalPeriodCropsTable.endDegreeDay)}
              name={"end_degree_day"}
              type="number"
              step={1}
              value={formik.values.end_degree_day}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.touched.end_degree_day ? formik.errors.end_degree_day : undefined}
            />
          </div>
        )}

        <Tooltip
          title={intl.formatMessage(
            addModalPeriodCropsTable.help_crop_coefficient
          )}
        >
          <div className={styled["input-label"]}>
            {intl.formatMessage(addModalPeriodCropsTable.crop_coefficient)}
            <div className={styled["help-icon"]}>
              <Icon
                name={ICON_NAMES_ENUM.help_circle}
                className={styled.file__icon}
              />
            </div>
          </div>
        </Tooltip>
        <Input
          name="crop_coefficient"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.crop_coefficient}
          className={
            !!formik.errors.crop_coefficient ? styled["input-invalid"] : ""
          }
        />

        <div className={styled.row}>
          <Button type="submit" disabled={!isDatepicker}>
            <FormattedMessage {...commonMessages.add} />
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
}

export { AddModalPeriodCropsTable };
