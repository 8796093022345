import { useHelper } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { DirectionalLightHelper } from "three";

export const Sun = (props: {
  azimuth: number;
  inclination: number;
  field_height: number;
  field_width: number;
}) => {
  const phi = (props.inclination) * (Math.PI / 180);
  const theta = props.azimuth * -(Math.PI / 180) + Math.PI;

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [z, setZ] = useState(0);
  const [xL, setXL] = useState(0);
  const [yL, setYL] = useState(0);
  const [zL, setZL] = useState(0);

  useEffect(() => {
    setX( (Math.sqrt(Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)) / 2 + 60) * Math.sin(phi) * Math.cos(theta));
    setY( (Math.sqrt(Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)) / 2 + 60) * Math.cos(phi));
    setZ( (Math.sqrt(Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)) / 2 + 60) * Math.sin(phi) * Math.sin(theta));
    setXL( (Math.sqrt(Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)) / 2 + 60) * Math.sin(phi) * Math.cos(theta));
    setYL( (Math.sqrt(Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)) / 2 + 60) * Math.cos(phi));
    setZL( (Math.sqrt(Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)) / 2 + 60) * Math.sin(phi) * Math.sin(theta));
  }, [props]);

  var directionalLightRef = useRef(null!);

  return (
    <mesh>
      <directionalLight
        ref={directionalLightRef}
        position={[xL, yL, zL]}
        intensity={1}
        shadow-mapSize={[1024, 1024]}
        castShadow
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[
              Math.sqrt(
                Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)
              ) / 2,
              -Math.sqrt(
                Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)
              ) / 2,
              Math.sqrt(
                Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)
              ) / 2,
              -Math.sqrt(
                Math.pow(props.field_height, 2) + Math.pow(props.field_width, 2)
              ) / 2,
            ]}
          
        />
      </directionalLight>

      <mesh position={[x, y, z]}>
        <sphereGeometry args={[10, 32]} />
        <meshStandardMaterial attach="material" color="yellow" transparent />
      </mesh>
    </mesh>
  );
};