import { array, lazy, number, object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import {
  azimuth_schema,
  field_size_schema,
  initial_offset_schema,
  panel_height_schema,
  panel_max_tilt_schema,
  panel_opacity_schema,
  panel_size_schema,
  panel_tilt_schema,
  panels_gap_schema,
  panels_number_schema,
} from "pages/StructuresCreate/StructuresSchema";

const rows_schema = (intl: IntlShape) =>
  lazy((arr) => {
    return array()
      .of(
        object().shape({
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_height") && {
              panel_height: panel_height_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_opacity") && {
              panel_opacity: panel_opacity_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "azimuth") && {
              azimuth: azimuth_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_size") && {
              panel_size: object().shape({
                x: panel_size_schema(intl),
                y: panel_size_schema(intl),
              }),
            }),
            
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_length") && {
              panel_length: panel_size_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_width") && {
              panel_width: panel_size_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "initial_offset") && {
              initial_offset: object().shape({
                x: initial_offset_schema(intl),
                y: initial_offset_schema(intl),
              }),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panels_number") && {
              panels_number: object().shape({
                x: panels_number_schema(intl),
                y: panels_number_schema(intl),
              }),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panels_gap") && {
              panels_gap: object().shape({
                x: panels_gap_schema(intl),
                y: panels_gap_schema(intl),
              }),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "gap_between_adjacent_tables") && {
              gap_between_adjacent_tables: panels_gap_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "pitch_between_tables") && {
              pitch_between_tables: panels_gap_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_max_tilt") && {
              panel_max_tilt: panel_max_tilt_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "panel_tilt") && {
              panel_tilt: panel_tilt_schema(intl),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "field_size") && {
              field_size: object().shape({
                x: field_size_schema(intl),
                y: field_size_schema(intl),
              }),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "weather_dataset_id") && {
              weather_dataset_id: number()
                .nullable()
                .required(intl.formatMessage(validationMessages.required)),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "production_dataset_id") && {
              production_dataset_id: number()
                .nullable()
                .required(intl.formatMessage(validationMessages.required)),
            }),
          ...(arr.length &&
            Object.hasOwn(arr[0], "crop_id") && {
              crop_id: number()
                .nullable()
                .required(intl.formatMessage(validationMessages.required)),
            }),
        })
      )
      .min(
        1,
        intl.formatMessage(validationMessages.minArrayLength, { number: 1 })
      );
  });

const BatchesSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    base_simulation_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required)),
    frequency: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    interests: array()
      .of(string())
      .min(
        1,
        intl.formatMessage(validationMessages.minArrayLength, { number: 1 })
      ),
    rows: rows_schema(intl),
  });

export default BatchesSchema;
