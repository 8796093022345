import { defineMessages } from "react-intl";

export default defineMessages({
  dragAndDrop : {
    id             : "WaterBalanceDropItemMessages.dragAndDrop",
    defaultMessage : "Drag and Drop here or select",
  },
  weatherDatasets : {
    id             : "WaterBalanceDropItemMessages.weatherDatasets",
    defaultMessage : "Weather Dataset",
  },
  productionDatasets : {
    id             : "WaterBalanceDropItemMessages.productionDatasets",
    defaultMessage : "Electrical Production Dataset",
  },
  etpDatasets : {
    id             : "WaterBalanceDropItemMessages.etpDatasets",
    defaultMessage : "Evapotranspiration Dataset",
  },
  soilInformation : {
    id             : "WaterBalanceDropItemMessages.soilInformation",
    defaultMessage : "Soil Information",
  },
});
