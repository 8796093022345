import styled from "components/WaterBalanceDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";

const SoilInformationsRenderList = ({ selected }: { selected: any }) => {
  return (
    <ul className={styled.ul}>
      <Line title="Name" value={selected.name} />
      <Line title="RU water" value={selected.ru_water} />
      <Line title="RFU water" value={selected.rfu_water} />
      <Line title="Irrigation" value={selected.irrigation ? 'True' : 'False'} />
    </ul>
  );
};

export default SoilInformationsRenderList;
