import { defineMessages } from "react-intl";

export default defineMessages({
  panelHeight : {
    id             : "StructuresRenderListMessages.panelHeight",
    defaultMessage : "Table Height",
  },
  upperTableTipHeight: {
    id: "StructuresRenderListMessages.upperTableTipHeight",
    defaultMessage: "Upper table tip height",
  },
  lowerTableTipHeight: {
    id: "StructuresRenderListMessages.lowerTableTipHeight",
    defaultMessage: "Lower table tip height",
  },
  rotationAxisHeight: {
    id: "StructuresRenderListMessages.rotationAxisHeight",
    defaultMessage: "Rotation Axis Height",
  },
  tableOpacity : {
    id             : "StructuresRenderListMessages.tableOpacity",
    defaultMessage : "Panel Opacity",
  },
  tableSize : {
    id             : "StructuresRenderListMessages.tableSize",
    defaultMessage : "Table Size",
  },
  tableWidth: {
    id: "StructuresRenderListMessages.tableWidth",
    defaultMessage: "Table Width",
  },
  tableLength: {
    id: "StructuresRenderListMessages.tableLength",
    defaultMessage: "Table Length",
  },
  initialOffset : {
    id             : "StructuresRenderListMessages.initialOffset",
    defaultMessage : "Initial Offset",
  },
  numberOfPanels : {
    id             : "StructuresRenderListMessages.numberOfPanels",
    defaultMessage : "Number of tables",
  },
  spaceBetweenPanels : {
    id             : "StructuresRenderListMessages.spaceBetweenPanels",
    defaultMessage : "Space Between Panels",
  },
  pitchBetweenPanels: {
    id: "StructuresRenderListMessages.pitchBetweenPanels",
    defaultMessage: "Pitch between tables",
  },
  gapBetweenPanels: {
    id: "StructuresRenderListMessages.gapBetweenPanels",
    defaultMessage: "Gap between adjacent tables",
  },
  fieldSize : {
    id             : "StructuresRenderListMessages.fieldSize",
    defaultMessage : "Field Size",
  },
  azimuth : {
    id             : "StructuresRenderListMessages.azimuth",
    defaultMessage : "Azimuth",
  },
  structureType : {
    id             : "StructuresRenderListMessages.structureType",
    defaultMessage : "Structure Type",
  },
  panelMaxTilt : {
    id             : "StructuresRenderListMessages.panelMaxTilt",
    defaultMessage : "Panel max Tilt",
  },
  panelTilt : {
    id             : "StructuresRenderListMessages.panelTilt",
    defaultMessage : "Panel Tilt",
  },
  panelTranslation : {
    id             : "StructuresRenderListMessages.panelTranslation",
    defaultMessage : "Panel Translation",
  },
  maximalLateralTranslation : {
    id             : "StructuresRenderListMessages.maximalLateralTranslation",
    defaultMessage : "Maximal Lateral Translation",
  },
  lateralOffset : {
    id             : "StructuresRenderListMessages.lateralOffset",
    defaultMessage : "Lateral Offset",
  },
  nonFarmingBandWidth: {
    id             : "StructuresRenderListMessages.nonFarmingBandWidth",
    defaultMessage : "Non farming band width",
  },
});
