import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { DnDTypes } from "constants/dndTypes";
import simulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import preRender from "assets/pre-render-partial.png";
import TextArea from "components/common/textArea/TextArea";
import Select from "components/common/select/Select";
import Input from "components/common/input/Input";
import CheckBox from "components/common/checkBox/CheckBox";
import { IFormik } from "components/common/FormikType";
import { getNavigationState } from "store/navigation/selectors";
import useSingeData from "./useSingeData";
import styled from "./styled.module.scss";
import { resetSimulation } from "store/simulations/actions";
import { drawStructureImage } from "store/structures/api";
import { useParams } from "react-router-dom";
import WaterBalanceDropItem from "components/WaterBalanceDropItem/WaterBalanceDropItem";
import SimulationCheckBox from "components/SimulationDropItem/SimulationCheckBox";
import { Tooltip } from "@mui/material";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import WaterBalanceCheckBox from "components/WaterBalanceDropItem/WaterBalanceCheckBox";
import clsx from "clsx";
import StructuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import InputRange from "components/common/inputRange/InputRange";
import { Structure_3D } from "components/Structure_3D/Structure_3D";
import Button from "components/common/button/Button";
import { getProjectById } from "store/projects/selectors";

export interface IWaterBalancesState {
  name: string;
  description: string;
  resolution: number;
  frequency: string;
  max_scale_value: number;
  enable_max_scale: boolean;
  crop_id: number | null;
  structure_id: number | null;
  weather_dataset_id: number | null;
  production_dataset_id: number | null;
  steering_algorithm_id: number | null;
  ETP_dataset_id: number | null;
  soil_information_id: number | null;
  grassland_primary_yield_calculation: boolean;
  edge_effect: boolean | undefined;
}

interface IWaterBalanceBody {
  formik: IFormik<IWaterBalancesState>;
  onRunSimulation?: () => void;
}

export const frequencyOptions = [
  { value: "5min", label: "5 min" },
  { value: "15min", label: "15 min" },
  { value: "30min", label: "30 min" },
  { value: "1H", label: "1 hour" },
  { value: "2H", label: "2 hour" },
];

const WaterBalanceBody = ({ formik, onRunSimulation }: IWaterBalanceBody) => {
  const intl = useIntl();
  const { client_id, project_id } = useParams();
  const dispatch = useDispatch();
  const [image, setImage] = useState(preRender);
  const [imageLoading, setImageLoading] = useState(false);
  const currentProject = useSelector(getProjectById(project_id));
  const [defaultPositionCanvas, setDefaultPositionCanvas] = useState(false);
  const [azimuthSolar, setAzimuthSolar] = useState(20);
  const [zenithSolar, setZenithSolar] = useState(20);
  const {
    structures,
    weather_datasets,
    production_datasets,
    etp_datasets,
    soil_informations,
    crops,
    steering_algorithms,
  } = useSelector(getNavigationState);

  const {
    production_dataset,
    structure,
    crop,
    steering_algorithm,
    weather_dataset,
    etp_dataset,
    soil_information,
    getStructure,
    getCrop,
    getSteeringAlgorithm,
    getProductionData,
    getWeatherData,
    getEtpData,
    getSoilInformation,
    nullStateValue,
  } = useSingeData();

  const getInputProps = (key: keyof IWaterBalancesState) => ({
    name: key,
    value: formik.values[key] as string | number,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
  });

  const onSelect = (key: keyof IWaterBalancesState) => (option: any) => {
    formik.setFieldValue(key, option.value);
  };

  const onChangeCheckBox =
    (key: keyof IWaterBalancesState) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(key, e.target.checked);
    };

  const onDraw = () => {
    setImageLoading(true);
    drawStructureImage({
      clientId: client_id,
      projectId: project_id,
      ...structure,
    })
      .then((img) => {
        setImage(img);
        setImageLoading(false);
      })
      .catch(async (error) => {
        setImage(preRender);
        setImageLoading(false);
      });
  };

  const onDrop = (type: DnDTypes, id: number) => {
    switch (type) {
      case DnDTypes.STRUCTURE:
        formik.setFieldValue("structure_id", id);
        break;
      case DnDTypes.CROP:
        formik.setFieldValue("crop_id", id);
        break;
      case DnDTypes.STEERING_ALGORITHM:
        formik.setFieldValue("steering_algorithm_id", id);
        break;
      case DnDTypes.DATA_WEATHER:
        formik.setFieldValue("weather_dataset_id", id);
        break;
      case DnDTypes.DATA_PROD:
        formik.setFieldValue("production_dataset_id", id);
        break;
      case DnDTypes.DATA_ETP:
        formik.setFieldValue("ETP_dataset_id", id);
        break;
      case DnDTypes.SOIL_INFORMATION:
        formik.setFieldValue("soil_information_id", id);
        break;
    }
  };

  useEffect(() => {
    if (structure) {
      onDraw();
      if (structure.structure_type == "fixed")
        formik.setFieldValue("steering_algorithm_id", null);
    }
  }, [structure]);

  useEffect(() => {
    if (steering_algorithm && crop && crop.id != steering_algorithm.crop_id) {
      formik.setFieldValue("steering_algorithm_id", null);
      nullStateValue("steering_algorithm");
    }
  }, [crop]);

  useEffect(() => {
    if (formik.values.structure_id) getStructure(formik.values.structure_id);
  }, [formik.values.structure_id]);

  useEffect(() => {
    if (formik.values.crop_id) {
      getCrop(formik.values.crop_id);
    }
  }, [formik.values.crop_id]);

  useEffect(() => {
    if (!formik.values.steering_algorithm_id)
      nullStateValue("steering_algorithm");
    if (formik.values.steering_algorithm_id)
      getSteeringAlgorithm(formik.values.steering_algorithm_id);
  }, [formik.values.steering_algorithm_id]);

  useEffect(() => {
    if (!formik.values.soil_information_id) nullStateValue("soil_information");
    else getSoilInformation(formik.values.soil_information_id);
  }, [formik.values.soil_information_id]);

  useEffect(() => {
    if (formik.values.weather_dataset_id)
      getWeatherData(formik.values.weather_dataset_id);
  }, [formik.values.weather_dataset_id]);

  useEffect(() => {
    if (!formik.values.production_dataset_id) nullStateValue("production_dataset");
    if (formik.values.production_dataset_id)
      getProductionData(formik.values.production_dataset_id);
  }, [formik.values.production_dataset_id]);

  useEffect(() => {
    if (!formik.values.ETP_dataset_id) nullStateValue("etp_dataset");
    if (formik.values.ETP_dataset_id) getEtpData(formik.values.ETP_dataset_id);
  }, [formik.values.ETP_dataset_id]);

  useEffect(() => {
    return () => {
      dispatch(resetSimulation());
      formik.resetForm();
    };
  }, []);

  return (
    <div className={styled.grid}>
      <div className={styled.column}>
        <WaterBalanceDropItem
          accept={DnDTypes.STRUCTURE}
          onDrop={onDrop}
          variants={structures}
          selected={structure ? structure : { name: "None", id: null }}
          formik={formik}
          formikKey="structure_id"
        />
        <WaterBalanceDropItem
          accept={DnDTypes.CROP}
          onDrop={onDrop}
          variants={crops}
          selected={crop ? crop : { name: "None", id: null }}
          formik={formik}
          formikKey="crop_id"
        />
        {formik.values.structure_id &&
          structure &&
          structure.structure_type == "tracker" && (
            <WaterBalanceDropItem
              accept={DnDTypes.STEERING_ALGORITHM}
              onDrop={onDrop}
              variants={[
                { id: null, name: "None" },
                ...steering_algorithms.filter(
                  (item: { crop_id: number | undefined }) =>
                    item.crop_id == crop?.id
                ),
              ]}
              selected={
                steering_algorithm
                  ? steering_algorithm
                  : { name: "None", id: null }
              }
              formik={formik}
              formikKey="steering_algorithm_id"
            />
          )}
        <WaterBalanceDropItem
          accept={DnDTypes.SOIL_INFORMATION}
          onDrop={onDrop}
          variants={soil_informations}
          selected={
            soil_information ? soil_information : { name: "None", id: null }
          }
          formik={formik}
          formikKey="soil_information_id"
        />
        <WaterBalanceDropItem
          accept={DnDTypes.DATA_WEATHER}
          onDrop={onDrop}
          variants={weather_datasets}
          selected={
            weather_dataset ? weather_dataset : { name: "None", id: null }
          }
          formik={formik}
          formikKey="weather_dataset_id"
        />
        {/* <WaterBalanceDropItem
          accept={DnDTypes.DATA_PROD}
          onDrop={onDrop}
          variants={[{ name: "None", id: null }, ...production_datasets]}
          selected={
            production_dataset ? production_dataset : { name: "None", id: null }
          }
          formik={formik}
          formikKey="production_dataset_id"
        /> */}
        <WaterBalanceDropItem
          accept={DnDTypes.DATA_ETP}
          onDrop={onDrop}
          variants={etp_datasets}
          selected={etp_dataset ? etp_dataset : { name: "None", id: null }}
          formik={formik}
          formikKey="ETP_dataset_id"
        />

        <WaterBalanceCheckBox
          label={
            // <Tooltip title="help message goes here">
              <div className={styled["checkbox-label"]}>
                {intl.formatMessage(
                  SimulationCreateMessages.grasslandPrimaryYieldCalculation
                )}
                <div className={styled["help-icon"]}>
                  <Icon
                    name={ICON_NAMES_ENUM.help_circle}
                    className={styled.file__icon}
                  />
                </div>
              </div>
            // </Tooltip>
          }
          formik={formik}
          formikKey="grassland_primary_yield_calculation"
        />
      </div>

      <div className={styled.rightSection}>
        <h2 className={styled.sectionTitle}>
          <FormattedMessage {...simulationCreateMessages.simulationSetting} />
        </h2>

        <div className={styled.renderContainer}>
          <div className={styled.titleRow}>
            <h2 className={styled.sectionTitle}>
              <FormattedMessage {...StructuresCreateMessages.visualDisplay} />
            </h2>

            <Button
              onClick={() => setDefaultPositionCanvas(true)}
              variant="text"
              className={styled.drawButton}
            >
              <FormattedMessage {...StructuresCreateMessages.defaultPosition} />
            </Button>
          </div>

          <div className={clsx(styled.renderContainer, styled.visuContainer)}>
            {currentProject && structure && (
              <Structure_3D
                panel_height={structure.panel_height}
                panel_opacity={structure.panel_opacity}
                panel_x={structure.panel_x}
                panel_y={structure.panel_y}
                initial_offset_x={structure.initial_offset_x}
                initial_offset_y={structure.initial_offset_y}
                panels_number_x={structure.panels_number_x}
                panels_number_y={structure.panels_number_y}
                panels_gap_x={
                  structure.panels_gap_x +
                  (structure.structure_type == "fixed" ? 0 : 0)
                }
                panels_gap_y={
                  structure.panels_gap_y +
                  (structure.structure_type == "tracker"
                    ? 0
                    : 0)
                }
                field_size_x={structure.field_size_x}
                field_size_y={structure.field_size_y}
                azimuth={structure.azimuth}
                structure_type={structure.structure_type}
                static_angle={structure.static_angle}
                tracking_max_angle={structure.tracking_max_angle}
                backtracking={structure.backtracking}
                displacement_tracking={structure.displacement_tracking}
                solar_position={"simple"}
                azimuth_solar={azimuthSolar}
                zenith_solar={zenithSolar}
                day={structure.day}
                hour={structure.hour}
                lat={currentProject.lat}
                long={currentProject.long}
                points={structure.points}
                defaultPositionBool={defaultPositionCanvas}
                setDefaultPositionBool={setDefaultPositionCanvas}
                no_crop_zone={structure.no_crop_zone}
              />
            )}
          </div>

          <div className={clsx(styled.mt, styled.titleRow)}>
            <Tooltip
              title={
                "This sun position is only used in the 3D visual display of the shade."
              }
            >
              <h2 className={styled.sectionTitle}>
                <FormattedMessage {...StructuresCreateMessages.sunPosition} />
                <div className={styled["help-icon"]}>
                  <Icon
                    name={ICON_NAMES_ENUM.help_circle}
                    className={styled.file__icon}
                  />
                </div>
              </h2>
            </Tooltip>
          </div>

          <InputRange
            label={intl.formatMessage(StructuresCreateMessages.azimuth)}
            values={[azimuthSolar]}
            min={-90}
            max={90}
            step={1}
            onChange={(values) => setAzimuthSolar(values[0])}
          />

          <InputRange
            label={intl.formatMessage(StructuresCreateMessages.zenith)}
            values={[zenithSolar]}
            min={-90}
            max={90}
            step={1}
            onChange={(values) => setZenithSolar(values[0])}
          />
          {/* <img src={image} alt="" /> */}
        </div>

        <Input
          label={intl.formatMessage(simulationCreateMessages.resolution)}
          type="number"
          integerNumber
          {...getInputProps("resolution")}
          disabled
        />

        <TextArea
          label={intl.formatMessage(simulationCreateMessages.description)}
          {...getInputProps("description")}
        />

        <div className={styled.row}>
          <Select
            label={intl.formatMessage(
              simulationCreateMessages.simulationFrequency
            )}
            options={frequencyOptions}
            value={frequencyOptions.find(
              ({ value }) => value === formik.values.frequency
            )}
            onChange={onSelect("frequency")}
            isDisabled
          />

          {/* <Input
            label={intl.formatMessage(simulationCreateMessages.maxScale)}
            type="number"
            integerNumber
            {...getInputProps("max_scale_value")}
          /> */}
        </div>

        {/* <CheckBox
          label={intl.formatMessage(simulationCreateMessages.setMaxPlot)}
          checked={formik.values.enable_max_scale}
          onChange={onChangeCheckBox("enable_max_scale")}
        /> */}
      </div>
    </div>
  );
};

export default WaterBalanceBody;
