import { defineMessages } from "react-intl";

export default defineMessages({
  simulationSetting: {
    id: "WaterBalanceResultBodyMessages.simulationSetting",
    defaultMessage: "Simulation Setting",
  },
  simulationInProgress: {
    id: "WaterBalanceResultBodyMessages.simulationInProgress",
    defaultMessage: "Simulation In Progress...",
  },
  simulationResults: {
    id: "WaterBalanceResultBodyMessages.simulationResults",
    defaultMessage: "Simulation Results",
  },
  inProgress: {
    id: "WaterBalanceResultBodyMessages.inProgress",
    defaultMessage: "In Progress: Stage {value} of 2",
  },
  periodSimulation: {
    id: "WaterBalanceResultBodyMessages.periodSimulation",
    defaultMessage: "Period of simulation",
  },
  periodsSimulation: {
    id: "WaterBalanceResultBodyMessages.periodsSimulation",
    defaultMessage: "Periods of simulation",
  },
  annualSimu: {
    id: "WaterBalanceResultBodyMessages.annualSimu",
    defaultMessage: "Annual water balance simulation",
  },
  irradianceRefZone: {
    id: "WaterBalanceResultBodyMessages.irradianceRefZone",
    defaultMessage: "Irradiance control zone (Wh/m²)",
  },
  betweenPanels: {
    id: "WaterBalanceResultBodyMessages.betweenPanels",
    defaultMessage: "Irradiance Between Panels (Wh/m²)",
  },
  underPanels: {
    id: "WaterBalanceResultBodyMessages.underPanels",
    defaultMessage: "Irradiance Under Panels (Wh/m²)",
  },
  agrilPV: {
    id: "WaterBalanceResultBodyMessages.agrilPV",
    defaultMessage: "Irradiance AgrilPV zone (Wh/m²)",
  },
  heterogeneity: {
    id: "WaterBalanceResultBodyMessages.heterogeneity",
    defaultMessage: "Heterogeneity",
  },
  meanDailyEtpRefZone: {
    id: "WaterBalanceResultBodyMessages.meanDailyEtpRefZone",
    defaultMessage: "Mean Daily ETP control zone (mm)",
  },
  meanDailyEtpUnderPanels : {
    id: "WaterBalanceResultBodyMessages.meanDailyEtpUnderPanels",
    defaultMessage: "Mean Daily ETP under panels (mm)",
  },
  meanDailyEtpBetweenPanels: {
    id: "WaterBalanceResultBodyMessages.meanDailyEtpBetweenPanels",
    defaultMessage: "Mean Daily ETP between panels (mm)",
  },
  meanDailyEtpAgriPVZone: {
    id: "WaterBalanceResultBodyMessages.meanDailyEtpAgriPVZone",
    defaultMessage: "Mean Daily ETP AgriPV zone (mm)",
  },
  simulationFrequency: {
    id: "WaterBalanceResultBodyMessages.simulationFrequency",
    defaultMessage: "Simulation Frequency",
  },
  maxScaleValue: {
    id: "WaterBalanceResultBodyMessages.maxScaleValue",
    defaultMessage: "Max Scale Value",
  },
  resolution: {
    id: "WaterBalanceResultBodyMessages.resolution",
    defaultMessage: "Resolution",
  },
  hintLegend: {
    id: "WaterBalanceResultBodyMessages.hintLegend",
    defaultMessage: "Hint: Try to click on legend's values",
  },
  hintChartToPng: {
    id: "WaterBalanceResultBodyMessages.hintChartToPng",
    defaultMessage: "Download chart in png",
  },
  hintChartToCsv: {
    id: "WaterBalanceResultBodyMessages.hintChartToCsv",
    defaultMessage: "Download chart's data in csv",
  },
  hintTableToPng: {
    id: "WaterBalanceResultBodyMessages.hintTableToPng",
    defaultMessage: "Download table in png",
  },
  hintTableToCsv: {
    id: "WaterBalanceResultBodyMessages.hintTableToCsv",
    defaultMessage: "Download table's data in csv",
  },
  dailyDataAllPeriod: {
    id: "WaterBalanceResultBodyMessages.dailyDataAllPeriod",
    defaultMessage: "Daily Data from all Periods",
  },
  dataPerPeriod: {
    id: "WaterBalanceResultBodyMessages.dataPerPeriod",
    defaultMessage: "Data per Period",
  },
  irrPerPeriod: {
    id: "WaterBalanceResultBodyMessages.irrPerPeriod",
    defaultMessage: "Irradiance per Period",
  },
  ETPPerPeriod: {
    id: "WaterBalanceResultBodyMessages.ETPPerPeriod",
    defaultMessage: "ETP per Period",
  },
  ProdPerPeriod: {
    id: "WaterBalanceResultBodyMessages.ProdPerPeriod",
    defaultMessage: "Production per Period",
  },
  irrOfPeriod: {
    id: "WaterBalanceResultBodyMessages.irrOfPeriod",
    defaultMessage: "Irradiance of ':period_name' Period",
  },
  ETPOfPeriod: {
    id: "WaterBalanceResultBodyMessages.ETPOfPeriod",
    defaultMessage: "ETP of ':period_name' Period",
  },
  ProdOfPeriod: {
    id: "WaterBalanceResultBodyMessages.ProdOfPeriod",
    defaultMessage: "Production of ':period_name' Period",
  },
  irrAllPeriods: {
    id: "WaterBalanceResultBodyMessages.irrAllPeriods",
    defaultMessage: "Daily Irradiance of all Periods",
  },
  ETPAllPeriods: {
    id: "WaterBalanceResultBodyMessages.ETPAllPeriods",
    defaultMessage: "Daily ETP of all Periods",
  },
  ProdAllPeriods: {
    id: "WaterBalanceResultBodyMessages.ProdAllPeriods",
    defaultMessage: "Daily Production of all Periods",
  },
  waterStressNumber: {
    id: "WaterBalanceResultBodyMessages.waterStressNumber",
    defaultMessage: "Number of Days in water stress",
  },
  irrigationValue: {
    id: "WaterBalanceResultBodyMessages.irrigationValue",
    defaultMessage: "Irrigation per period (mm)",
  },
  indicator: {
    id: "WaterBalanceResultBodyMessages.indicator",
    defaultMessage: "Indicator",
  },
    frostStress: {
    id: "SimulationResultBodyMessages.frostStress",
    defaultMessage: "Frost stress (days)",
  },
  heatStress: {
    id: "SimulationResultBodyMessages.heatStress",
    defaultMessage: "Heat stress (days)",
  },
  days: {
    id: "SimulationResultBodyMessages.days",
    defaultMessage: "Days",
  },
  titleThermalStressFreeze: {
    id: "SimulationResultBodyMessages.titleThermalStress",
    defaultMessage: "Days of frost",
  },
  titleThermalStressHot: {
    id: "SimulationResultBodyMessages.titleThermalStress",
    defaultMessage: "Days of heat stress",
  },
  frostStressHelp: {
    id: "SimulationResultBodyMessages.frostStressHelp",
    defaultMessage: "The number of days with minimum temperature Tx < 0°C",
  },
  heatStressHelp: {
    id: "SimulationResultBodyMessages.heatStressHelp",
    defaultMessage: "The number of days with maximum temperature Tx > 25°C",
  },
  agriPVZone: {
    id: "SimulationResultBodyMessages.agriPVZone",
    defaultMessage: "in agriPV zone",
  },
  controlZone: {
    id: "SimulationResultBodyMessages.controlZone",
    defaultMessage: "in control zone",
  },
});
