import React, { useState } from "react";
import MainContent from "components/MainContent/MainContent";
import EditName from "components/EditName/EditName";
import styled from "./styled.module.scss";
import Button from "components/common/button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { FormikHelpers, useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import BatchesSchema from "pages/BatchesCreate/BatchesSchema";
import batchesCreateMessages from "pages/BatchesCreate/BatchesCreateMessages";
import BatchesBody, { IBatchesState } from "components/BatchesBody/BatchesBody";
import { createBatch, runBatchSimulation } from "store/batches/actions";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import { frequencyOptions } from "components/SimulationBody/SimulationBody";

export const getDefaultValues = (intl: any): IBatchesState => {
  return {
    name: intl.formatMessage(batchesCreateMessages.batchUnnamed),
    base_simulation_id: null,
    frequency: frequencyOptions[3].value,
    interests: [],
    rows: [],
  };
};

const BatchesCreate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  useUpdateNavigation();

  const onBack = () => {
    navigate(
      ROUTES.BATCHES.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  };

  const toEditPage = (batch: any) => {
    navigate(
      ROUTES.BATCHES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", batch.id as string)
    );
  };

  const toResultPage = (batch: any, id: string) =>
    navigate(
      ROUTES.BATCHES_RESULT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":batch_id", batch.id as string)
        .replace(":id", id)
    );

  const onCreate = (
    values: IBatchesState,
    actions: FormikHelpers<IBatchesState>
  ) => {
    dispatch(
      createBatch({
        ...values,
        clientId: client_id,
        projectId: project_id,
        callback: toEditPage,
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onCreate,
    validationSchema: BatchesSchema(intl),
  });

  usePrompt(
    !isEqual(formik.values, getDefaultValues(intl)) && !formik.isSubmitting && !isLoading
  );

  const runSimulation = (batch: any) => {
    dispatch(
      runBatchSimulation({
        clientId: client_id,
        projectId: project_id,
        batchId: batch.id,
        callback: (id: string) => toResultPage(batch, id),
      })
    );
  };

  const onRunSimulation = () => {
    setIsLoading(true);
    formik.validateForm().then((errors: any) => {
      Object.keys(errors).forEach((key) => formik.setFieldTouched(key, true));
    });

    if (formik.isValid) {
      dispatch(
        createBatch({
          ...formik.values,
          clientId: client_id,
          projectId: project_id,
          callback: runSimulation,
        })
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              autoFocus
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(batchesCreateMessages.name)}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <BatchesBody formik={formik} onRunSimulation={onRunSimulation} />
      </MainContent>
    </form>
  );
};

export default BatchesCreate;
