import React, { useEffect, useState } from "react";
import Header from "components/Header/Header";
import Button from "components/common/button/Button";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import mainLayoutMessages from "components/MainLayout/MainLayoutMessages";
import Accordion from "components/Accordion/Accordion";
import AccordionItem from "components/AccordionItem/AccordionItem";
import { ROUTES } from "constants/routes";
import commonMessages from "components/common/CommonMessages";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectById } from "store/projects/selectors";
import { deleteProject } from "store/projects/actions";
import AccordionDragItem from "components/AccordionDragItem/AccordionDragItem";
import { DnDTypes } from "constants/dndTypes";
import withAuthProtection from "hoc/authProtection";
import { getNavigationState } from "store/navigation/selectors";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import ItemDrawerFoldable from "components/ItemDrawerFoldable/ItemDrawerFoldable";
import { Loader } from "components/common/loader/Loader";

interface IMenuItem {
  id: number;
  name: string;
}

const MainLayout = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const currentProject = useSelector(getProjectById(project_id));
  const {
    structures,
    crops,
    weather_datasets,
    production_datasets,
    etp_datasets,
    simulations,
    steering_algorithms,
    batches,
    batches_water_balance,
    soil_informations,
    water_balances,
    sizing_optimizations,
  } = useSelector(getNavigationState);

  const [isDeleteWarning, setIsDeleteWarning] = useState(false);
  useUpdateNavigation();

  const onHideWarning = () => setIsDeleteWarning(false);

  const onShowWarning = () => setIsDeleteWarning(true);

  const fixLink = (link: string) =>
    link
      .replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string);

  const onCallback = () => {
    navigate(ROUTES.PROJECTS_LIST.replace(":client_id", client_id as string));
  };

  const onDelete = () => {
    dispatch(
      deleteProject({
        clientId: client_id,
        projectId: project_id,
        callback: onCallback,
      })
    );
  };

  const getResultLink = (simulationId: string, resultId: string) =>
    ROUTES.SIMULATIONS_RESULT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":simulation_id", simulationId)
      .replace(":id", resultId);

  const getWaterBalanceResultLink = (
    waterBalanceId: string,
    resultId: string
  ) =>
    ROUTES.WATER_BALANCES_RESULT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":water_balance_id", waterBalanceId)
      .replace(":id", resultId);

  const getBatchResultLink = (batchId: string, resultId: string) =>
    ROUTES.BATCHES_RESULT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":batch_id", batchId)
      .replace(":id", resultId);

  const getBatchSimulationResultLink = (
    batchId: string,
    resultId: string,
    simulationId: string
  ) =>
    ROUTES.BATCHES_SIMULATION_RESULT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":batch_id", batchId)
      .replace(":result_id", resultId)
      .replace(":simulation_id", simulationId);

  const getBatchWaterBalanceResultLink = (
    batchWaterBalanceId: string,
    resultId: string
  ) => {
    return ROUTES.BATCHES_WATER_BALANCE_RESULT.replace(
      ":client_id",
      client_id as string
    )
      .replace(":project_id", project_id as string)
      .replace(":batch_water_balance_id", batchWaterBalanceId)
      .replace(":id", resultId);
  };

  const getSizingOptimizationResultLink = (
    sizingOptimizationId: string,
    resultId: string
  ) => {
    return ROUTES.SIZING_OPTIMIZATION_RESULT.replace(
      ":client_id",
      client_id as string
    )
      .replace(":project_id", project_id as string)
      .replace(":sizing_optimization_id", sizingOptimizationId)
      .replace(":id", resultId);
  };

  const getBatchWaterBalanceSimulationResultLink = (
    batchWaterBalanceId: string,
    resultId: string,
    simulationId: string
  ) =>
    ROUTES.BATCHES_WATER_BALANCE_SIMULATION_RESULT.replace(
      ":client_id",
      client_id as string
    )
      .replace(":project_id", project_id as string)
      .replace(":batch_water_balance_id", batchWaterBalanceId)
      .replace(":result_id", resultId)
      .replace(":simulation_water_balance_id", simulationId);

  const getSimulationLink = (simulationId: string | number) =>
    ROUTES.SIMULATIONS_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(simulationId));

  const getWaterBalanceLink = (waterBalanceId: string | number) =>
    ROUTES.WATER_BALANCES_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(waterBalanceId));

  const getBatchLink = (batchId: string | number) =>
    ROUTES.BATCHES_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(batchId));

  const getBatchWaterBalanceLink = (batchWaterBalanceId: string | number) =>
    ROUTES.BATCHES_WATER_BALANCE_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(batchWaterBalanceId));

  const getStructureLink = (structureId: string | number) =>
    ROUTES.STRUCTURES_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(structureId));

  const getCropLink = (cropId: string | number) =>
    ROUTES.CROPS_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(cropId));

  const getSteeringAlgorithmCreateByCropLink = (cropId: string | number) =>
    ROUTES.STEERING_ALGORITHM_LIST_FROM_CROP.replace(
      ":client_id",
      client_id as string
    )
      .replace(":project_id", project_id as string)
      .replace(":id", String(cropId));

  const getSteeringAlgorithmLink = (steeringAlgorithmId: string | number) =>
    ROUTES.STEERING_ALGORITHM_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(steeringAlgorithmId));

  const getSoilInformationLink = (soilInformationId: string | number) =>
    ROUTES.SOIL_INFORMATION_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(soilInformationId));

  const getDatasetLink = (datasetId: string | number) =>
    ROUTES.DATASETS_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(datasetId));

  const getSizingOptimizationLink = (datasetId: string | number) =>
    ROUTES.SIZING_OPTIMIZATION_EDIT.replace(":client_id", client_id as string)
      .replace(":project_id", project_id as string)
      .replace(":id", String(datasetId));

  const getSizingOptimizationBatchResultLink = (
    sizingOptimizationId: string,
    resultId: string,
    batchId: string
  ) =>
    ROUTES.SIZING_OPTIMIZATION_BATCH_RESULT.replace(
      ":client_id",
      client_id as string
    )
      .replace(":project_id", project_id as string)
      .replace(":sizing_optimization_id", sizingOptimizationId)
      .replace(":result_id", resultId)
      .replace(":id", batchId);

  const getSizingOptimizationSimulationResultLink = (
    sizingOptimizationId: string,
    resultId: string,
    batchId: string,
    simulationId: string
  ) =>
    ROUTES.SIZING_OPTIMIZATION_BATCH_SIMULATION_RESULT.replace(
      ":client_id",
      client_id as string
    )
      .replace(":project_id", project_id as string)
      .replace(":sizing_optimization_id", sizingOptimizationId)
      .replace(":result_id", resultId)
      .replace(":batch_result_id", batchId)
      .replace(":id", simulationId);

  const [isDragging, setIsDragging] = useState(false);
  const [layoutWidth, setLayoutWidth] = useState(250);
  useEffect(() => {
    const onMouseMove = (e: any) => {
      if (isDragging) {
        const layout = document.getElementById("layout");
        if (layout) {
          const rect = layout.getBoundingClientRect();

          const width = e.clientX - rect.x + 5;

          if (width > 200 && width < window.innerWidth / 2)
            setLayoutWidth(width);
        }
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [isDragging]);

  useEffect(() => {
    const onMouseUp = () => setIsDragging(false);

    document.addEventListener("mouseup", onMouseUp);
    return () => document.removeEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <>
      <Header />
      <div className={styled.header}>
        <div className={styled.row}>
          <h1 className={styled.projectTitle}>{currentProject?.name}</h1>
        </div>
      </div>

      <div
        className={styled.content}
        style={{
          gridTemplateColumns: `${layoutWidth}px 1fr`,
        }}
      >
        <div className={styled.stickyContainer}>
          <aside className={styled.aside} id={"layout"}>
            <div
              className={styled.dragBorderContainer}
            >
              <div
                className={styled.dragBorder}
                onMouseDown={() => setIsDragging(true)}
              />
            </div>
            <Accordion
              title={intl.formatMessage(commonMessages.structures)}
              to={fixLink(ROUTES.STRUCTURES)}
            >
              {structures.map((item: IMenuItem) => (
                <AccordionDragItem
                  key={item.id}
                  icon="s"
                  name={item.name}
                  dragType={DnDTypes.STRUCTURE}
                  data={item}
                  to={getStructureLink(item.id)}
                />
              ))}
            </Accordion>
            <Accordion
              title={intl.formatMessage(commonMessages.crops)}
              to={fixLink(ROUTES.CROPS)}
            >
              {crops.map((item: IMenuItem) => (
                <AccordionDragItem
                  key={item.id}
                  icon="c"
                  name={item.name}
                  dragType={DnDTypes.CROP}
                  data={item}
                  to={getCropLink(item.id)}
                />
              ))}
            </Accordion>
            <Accordion
              title={intl.formatMessage(commonMessages.steering)}
              to={fixLink(ROUTES.STEERING_ALGORITHM)}
              count={steering_algorithms.length}
            >
              {crops
                .filter((crop: IMenuItem) =>
                  steering_algorithms.find(
                    (steering_algorithm: any) =>
                      steering_algorithm.crop_id == crop.id
                  )
                )
                .map((item: IMenuItem) => (
                  <ItemDrawerFoldable
                    icon="c"
                    name={item.name}
                    to={getSteeringAlgorithmCreateByCropLink(item.id)}
                    key={item.id}
                  >
                    <div className={styled.resultsContainer}>
                      {steering_algorithms
                        .filter(
                          (steering_algorithm: any) =>
                            steering_algorithm.crop_id == item.id
                        )
                        .map((item: IMenuItem) => (
                          <AccordionDragItem
                            key={item.id}
                            icon="si"
                            name={item.name}
                            dragType={DnDTypes.STEERING_ALGORITHM}
                            data={item}
                            to={getSteeringAlgorithmLink(item.id)}
                          />
                        ))}
                    </div>
                  </ItemDrawerFoldable>
                ))}
            </Accordion>
            <Accordion
              title={intl.formatMessage(commonMessages.soil_information)}
              to={fixLink(ROUTES.SOIL_INFORMATIONS)}
            >
              {soil_informations.map((item: IMenuItem) => (
                <AccordionDragItem
                  key={item.id}
                  icon="so"
                  name={item.name}
                  dragType={DnDTypes.SOIL_INFORMATION}
                  data={item}
                  to={getSoilInformationLink(item.id)}
                />
              ))}
            </Accordion>
            <Accordion
              title={intl.formatMessage(commonMessages.datasets)}
              to={fixLink(ROUTES.DATASETS)}
            >
              {weather_datasets.map((item: any) => (
                <AccordionDragItem
                  key={item.id}
                  icon="dt"
                  name={item.name}
                  dragType={DnDTypes.DATA_WEATHER}
                  data={item}
                  to={getDatasetLink(item.id)}
                />
              ))}
              {production_datasets.map((item: any) => (
                <AccordionDragItem
                  key={item.id}
                  icon="de"
                  name={item.name}
                  dragType={DnDTypes.DATA_PROD}
                  data={item}
                  to={getDatasetLink(item.id)}
                />
              ))}
              {etp_datasets.map((item: any) => (
                <AccordionDragItem
                  key={item.id}
                  icon="dt"
                  name={item.name}
                  dragType={DnDTypes.DATA_ETP}
                  data={item}
                  to={getDatasetLink(item.id)}
                />
              ))}
            </Accordion>

            <Accordion
              title={intl.formatMessage(commonMessages.simulations)}
              to={fixLink(ROUTES.SIMULATIONS)}
            >
              {simulations.map((item: any) => (
                <div key={item.id}>
                  <ItemDrawerFoldable
                    icon="si"
                    name={item.name}
                    to={getSimulationLink(item.id)}
                  >
                    <div className={styled.resultsContainer}>
                      {item.results.map((result: any) => (
                        <AccordionItem
                          key={result.id}
                          icon="sr"
                          name={result.name}
                          to={getResultLink(item.id, result.id)}
                        />
                      ))}
                    </div>
                  </ItemDrawerFoldable>
                </div>
              ))}
            </Accordion>

            {/* <Accordion
            title={intl.formatMessage(commonMessages.sizing_optimization)}
            to={fixLink(ROUTES.SIZING_OPTIMIZATION)}
          >
            {sizing_optimizations.map((sizing_optimization: any) => (
              <ItemDrawerFoldable
                icon="b"
                name={sizing_optimization.name}
                to={getSizingOptimizationLink(sizing_optimization.id)}
                key={sizing_optimization.id}
              >
                <div className={styled.resultsContainer}>
                  {sizing_optimization.sizing_optimization_batches.map(
                    (result: any) => (
                      <ItemDrawerFoldable
                        icon="sr"
                        name={result.name}
                        to={getSizingOptimizationResultLink(
                          sizing_optimization.id,
                          result.id
                        )}
                        key={result.id}
                      >
                        <div className={styled.resultsContainer}>
                          {result.sizing_optimization_batch_rows?.map(
                            (batch_row: any) => (
                              <ItemDrawerFoldable
                                key={batch_row.id}
                                icon="sr"
                                name={batch_row.name}
                                to={getSizingOptimizationBatchResultLink(
                                  sizing_optimization.id,
                                  batch_row.base_batch_id,
                                  batch_row.id
                                )}
                              >
                                {batch_row.batch_result.map(
                                  (batch_result: any) => (
                                    <AccordionItem
                                      key={batch_result.id}
                                      icon="sr"
                                      name={batch_result.name}
                                      to={getSizingOptimizationSimulationResultLink(
                                        sizing_optimization.id,
                                        batch_row.base_batch_id,
                                        batch_row.id,
                                        batch_result.id
                                      )}
                                    />
                                  )
                                )}
                              </ItemDrawerFoldable>
                            )
                          )}
                        </div>
                      </ItemDrawerFoldable>
                    )
                  )}
                </div>
              </ItemDrawerFoldable>
            ))}
          </Accordion> */}

            <Accordion
              title={intl.formatMessage(commonMessages.waterBalances)}
              to={fixLink(ROUTES.WATER_BALANCES)}
            >
              {water_balances.map((item: any) => (
                <div key={item.id}>
                  <ItemDrawerFoldable
                    icon="si"
                    name={item.name}
                    to={getWaterBalanceLink(item.id)}
                  >
                    <div className={styled.resultsContainer}>
                      {item.results.map((result: any) => (
                        <AccordionItem
                          key={result.id}
                          icon="sr"
                          name={result.name}
                          to={getWaterBalanceResultLink(item.id, result.id)}
                        />
                      ))}
                    </div>
                  </ItemDrawerFoldable>
                </div>
              ))}
            </Accordion>

            <Accordion
              title={intl.formatMessage(commonMessages.batches)}
              to={fixLink(ROUTES.BATCHES)}
            >
              {batches.map((item: any) => (
                <ItemDrawerFoldable
                  icon="b"
                  name={item.name}
                  to={getBatchLink(item.id)}
                  key={item.id}
                >
                  <div className={styled.resultsContainer}>
                    {item.results.map((result: any) => (
                      <ItemDrawerFoldable
                        icon="sr"
                        name={result.name}
                        to={getBatchResultLink(item.id, result.id)}
                        key={item.id}
                      >
                        <div className={styled.resultsContainer}>
                          {result.simulations?.map((simulation: any) => (
                            <AccordionItem
                              key={simulation.id}
                              icon="sr"
                              name={simulation.name}
                              to={getBatchSimulationResultLink(
                                item.id,
                                result.id,
                                simulation.id
                              )}
                            />
                          ))}
                        </div>
                      </ItemDrawerFoldable>
                    ))}
                  </div>
                </ItemDrawerFoldable>
              ))}
            </Accordion>

            {/* <Accordion
            title={intl.formatMessage(commonMessages.batches_water_balance)}
            to={fixLink(ROUTES.BATCHES_WATER_BALANCE)}
          >
            {batches_water_balance.map((item: any) => (
              <ItemDrawerFoldable
                icon="b"
                name={item.name}
                to={getBatchWaterBalanceLink(item.id)}
                key={item.id}
              >
                <div className={styled.resultsContainer}>
                  {item.results.map((result: any) => (
                    <ItemDrawerFoldable
                      icon="sr"
                      name={result.name}
                      to={getBatchWaterBalanceResultLink(item.id, result.id)}
                      key={item.id}
                    >
                      <div className={styled.resultsContainer}>
                        {result.simulations_water_balance?.map(
                          (simulation: any) => (
                            <AccordionItem
                              key={simulation.id}
                              icon="sr"
                              name={simulation.name}
                              to={getBatchWaterBalanceSimulationResultLink(
                                item.id,
                                result.id,
                                simulation.id
                              )}
                            />
                          )
                        )}
                      </div>
                    </ItemDrawerFoldable>
                  ))}
                </div>
              </ItemDrawerFoldable>
            ))}
          </Accordion> */}
          </aside>
        </div>
        <Outlet />
      </div>

      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onDelete={onDelete}
        show={isDeleteWarning}
        onHide={onHideWarning}
      />
    </>
  );
};

export default withAuthProtection(MainLayout);
