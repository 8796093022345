import React, { useMemo } from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";
import { IStructure } from "pages/StructuresCreate/StructuresCreate";
import { useIntl } from "react-intl";
import StructuresRenderListMessages from "./StructuresRenderListMessages";

const StructuresRenderList = ({ selected }: { selected: IStructure }) => {
  const intl = useIntl();

  const { lower, upper } = useMemo(() => {
    if (!selected) return { lower: 0, upper: 0 };
    const angle =
      selected.structure_type == "fixed"
        ? selected.static_angle
        : selected.tracking_max_angle;
    const panel_size =
      selected.structure_type == "fixed" ? selected.panel_y : selected.panel_x;

    const val1 =
      Math.round(
        (selected.panel_height -
          (panel_size / 2) * Math.sin((angle * Math.PI) / 180)) *
          100
      ) / 100;
    const val2 =
      Math.round(
        (selected.panel_height +
          (panel_size / 2) * Math.sin((angle * Math.PI) / 180)) *
          100
      ) / 100;
    if (val1 <= val2) {
      return { lower: val1, upper: val2 };
    } else {
      return { lower: val2, upper: val1 };
    }
  }, [selected]);

  return (
    <ul className={styled.ul}>
      <Line
        title={intl.formatMessage(
          StructuresRenderListMessages.lowerTableTipHeight
        )}
        value={`${lower} m`}
      />
      {selected.structure_type == "tracker" && (
        <Line
          title={intl.formatMessage(StructuresRenderListMessages.rotationAxisHeight)}
          value={`${selected.panel_height} m`}
        />
      )}
      <Line
        title={intl.formatMessage(
          StructuresRenderListMessages.upperTableTipHeight
        )}
        value={`${upper} m`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.tableOpacity)}
        value={selected.panel_opacity}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.tableLength)}
        value={`${
          selected.structure_type == "fixed"
            ? selected.panel_x
            : selected.panel_y
        } m`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.tableWidth)}
        value={`${
          selected.structure_type == "fixed"
            ? selected.panel_y
            : selected.panel_x
        } m`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.initialOffset)}
        value={`${selected.initial_offset_x}x${selected.initial_offset_y} m`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.numberOfPanels)}
        value={`${selected.panels_number_x}x${selected.panels_number_y}`}
      />
      <Line
        title={intl.formatMessage(
          StructuresRenderListMessages.gapBetweenPanels
        )}
        value={`${selected.structure_type == 'fixed' ? selected.panels_gap_x - selected.panel_x : selected.panels_gap_y - selected.panel_y} m`}
      />
      <Line
        title={intl.formatMessage(
          StructuresRenderListMessages.pitchBetweenPanels
        )}
        value={`${selected.structure_type == 'fixed' ? selected.panels_gap_y : selected.panels_gap_x} m`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.fieldSize)}
        value={`${selected.field_size_x}x${selected.field_size_y} m`}
      />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.azimuth)}
        value={`${selected.azimuth} º`}
      />
      <br />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.nonFarmingBandWidth)}
        value={`${selected.no_crop_zone} m`}
      />
      <br />
      <Line
        title={intl.formatMessage(StructuresRenderListMessages.structureType)}
        value={selected.structure_type}
      />
      {selected.structure_type === "fixed" ? (
        <Line
          title={intl.formatMessage(StructuresRenderListMessages.panelTilt)}
          value={selected.static_angle}
        />
      ) : (
        <Line
          title={intl.formatMessage(StructuresRenderListMessages.panelMaxTilt)}
          value={selected.tracking_max_angle}
        />
      )}
    </ul>
  );
};

export default StructuresRenderList;
