import { defineMessages } from "react-intl";

export default defineMessages({
  dragAndDrop : {
    id             : "SimulationDropItemMessages.dragAndDrop",
    defaultMessage : "Drag and Drop here or select",
  },
  weatherDatasets : {
    id             : "SimulationDropItemMessages.weatherDatasets",
    defaultMessage : "Weather Dataset",
  },
  productionDatasets : {
    id             : "SimulationDropItemMessages.productionDatasets",
    defaultMessage : "Electrical Production Dataset",
  },
  etpDatasets : {
    id             : "SimulationDropItemMessages.etpDatasets",
    defaultMessage : "Evapotranspiration Dataset",
  },
  steeringAlgorithms : {
    id             : "SimulationDropItemMessages.steeringAlgorithms",
    defaultMessage : "Steering Algorithm",
  },
});
