import { useState } from "react";
import { useParams } from "react-router-dom";
import { IStructure } from "pages/StructuresCreate/StructuresCreate";
import { ICrops } from "pages/Crops/Crops";
import { IDatasetModel } from "components/DatasetModel/DatasetModel";
import { apiGET } from "store/services/api";
import { errorNotifications } from "utils/errorNotifications";
import { ISoilInformation } from "pages/SoilInformations/SoilInformations";
import { IDbSteeringAlgorithm } from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";

interface IState {
  structure: IStructure | null;
  crop: ICrops | null;
  steering_algorithm: IDbSteeringAlgorithm | null;
  weather_dataset: IDatasetModel | null;
  production_dataset: IDatasetModel | null;
  etp_dataset: IDatasetModel | null;
  soil_information: ISoilInformation | null;
}

function useSingeData() {
  const { client_id, project_id } = useParams();

  const [
    {
      production_dataset,
      weather_dataset,
      etp_dataset,
      structure,
      crop,
      steering_algorithm,
      soil_information,
    },
    setValues,
  ] = useState<IState>({
    structure: null,
    crop: null,
    steering_algorithm: null,
    weather_dataset: null,
    production_dataset: null,
    etp_dataset: null,
    soil_information: null,
  });

  const getStructure = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/structures/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, structure: data }));
  };

  const getCrop = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/crops/simulation_obj/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, crop: data }));
  };

  const getSteeringAlgorithm = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/steering_algorithms/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, steering_algorithm: data }));
  };

  const getSoilInformation = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/water_balances/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, soil_information: data }));
  };

  const getWeatherData = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/datasets/simulation_obj/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, weather_dataset: data }));
  };

  const getProductionData = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/datasets/simulation_obj/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, production_dataset: data }));
  };

  const getEtpData = async (id: string | number, Error = true) => {
    const { data } = await apiGET({
      url: `/clients/${client_id}/projects/${project_id}/datasets/simulation_obj/${id}`,
    }).catch((error) => {
      if (Error) errorNotifications(error.response.data.data);
    });

    setValues((prev) => ({ ...prev, etp_dataset: data }));
  };

  const nullStateValue = (
    valueType:
      | "structure"
      | "crop"
      | "steering_algorithm"
      | "weather_dataset"
      | "production_dataset"
      | "etp_dataset"
      | "soil_information"
  ) => {
    switch (valueType) {
      case "structure":
        setValues((prev) => ({ ...prev, structure: null }));
        break;
      case "crop":
        setValues((prev) => ({ ...prev, crop: null }));
        break;
      case "steering_algorithm":
        setValues((prev) => ({ ...prev, steering_algorithm: null }));
        break;
      case "weather_dataset":
        setValues((prev) => ({ ...prev, weather_dataset: null }));
        break;
      case "production_dataset":
        setValues((prev) => ({ ...prev, production_dataset: null }));
        break;
      case "etp_dataset":
        setValues((prev) => ({ ...prev, etp_dataset: null }));
        break;
      case "soil_information":
        setValues((prev) => ({ ...prev, soil_information: null }));
        break;
    }
  };

  return {
    production_dataset,
    weather_dataset,
    etp_dataset,
    structure,
    crop,
    steering_algorithm,
    soil_information,
    getStructure,
    getCrop,
    getSteeringAlgorithm,
    getWeatherData,
    getProductionData,
    getEtpData,
    getSoilInformation,
    nullStateValue,
  };
}

export default useSingeData;
