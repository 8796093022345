export const Floor = (props: { height: number; width: number, opacity: number }) => {
  return (
    <>
      <mesh
        position={[0, 0, 0]}
      >
        <boxGeometry
          attach="geometry"
          args={[props.height, 0.1, props.width]}
        />
        <meshStandardMaterial attach="material" color="#0f0" />
      </mesh>
      <mesh
        receiveShadow
        position={[0, 0, 0]}
      >
        <boxGeometry
          attach="geometry"
          args={[props.height, 0.1, props.width]}
        />
        <shadowMaterial transparent opacity={props.opacity} />
      </mesh>
    </>
  );
};
